import * as Vue from 'vue'
import Layout from '@/layout'
export default {
    path: '/mnm',
    name:'mnm',
    component: Layout,
    alwaysShow: true,
    meta: { title: 'menus.amn', icon: 'product-catalog' },
    children:[
      {
          path: 'material_no',
          name: 'material_no',
          component: () => import('@/views/purchase/apply_material_no/index.vue'),
          meta: { title: 'menus.material_num', icon: 'document'},
        },
      {
          path: 'approver_material_no',
          name: 'approver_mnm',
          hidden: true,
          component: () => import('@/views/purchase/apply_material_no/approver_material.vue'),
          meta: { title: 'menus.material_num', icon: 'chanpin'},
        },
      {
          path: 'material_class',
          name: 'material_class',
          component: () => import('@/views/purchase/apply_material_no/material_class.vue'),
          meta: { title: 'menus.material_classification', icon: 'material-catalog'},
        },
    ]
}
